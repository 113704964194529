<template>
  <div>
    
      <section class="vh-100" style="background-color: #9a616d">
        <div class="container py-5 h-100">
          <div
            class="row d-flex justify-content-center align-items-center h-100"
          >
            <div class="col col-xl-10">
              <div class="card" style="border-radius: 1rem; margin-top: 20px">
                <div class="row g-0">
                  <div class="col-md-6 col-lg-5">
                    <img
                      src="../assets/images/teacher-1.jpeg"
                      alt="login form"
                      class="img-fluid"
                      style="border-radius: 1rem"
                    />
                  </div>
                  <div class="col-md-6 col-lg-7 d-flex align-items-center">
                    <div class="card-body p-4 p-lg-5 text-black">
                      <form>
                        <div class="d-flex align-items-center mb-3 pb-1">
                          <img src="../assets/images/logo.png" />
                        </div>

                        <h5
                          class="fw-normal mb-3 pb-3"
                          style="letter-spacing: 1px"
                        >
                          Đăng nhập tài khoản của bạn
                        </h5>

                        <div class="form-outline mb-3">
                          <input
                            type="email"
                            id="form2Example17"
                            class="form-control form-control-lg"
                            v-model="email"
                          />
                          <label class="form-label" for="form2Example17"
                            >Tài khoản</label
                          >
                        </div>

                        <div class="form-outline mb-4">
                          <input
                            type="password"
                            id="form2Example27"
                            class="form-control form-control-lg"
                            v-model="password"
                          />
                          <label class="form-label" for="form2Example27"
                            >Mật khẩu</label
                          >
                        </div>

                        <div class="pt-1 mb-4">
                          <button
                            class="btn btn-dark btn-lg btn-block"
                            type="button"
                            v-on:click="loginWithAccount"
                          >
                            Đăng nhập
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    
  </div>
</template>

<script>
import { authentication } from "../firebase-config";

export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    loginWithAccount() {
      console.log(this.email);
      console.log(this.password);
      authentication
        .signInWithEmailAndPassword(this.email, this.password)
        .then((useCredential) => {
          var user = useCredential.user;
          console.log("Anh thanfh dejp trai", user);
          this.$router.push("/admin/account")
        });
    },
  },
};
</script>

<style>
</style>